import React from 'react'

export const WelfareSchemes = () => {
    return (
        <>
            <div class="container mt-5">
                <div class="card">
                    <div class="card-header">
                        <h4>Welfare Scheme</h4>
                    </div>
                    <div class="card-body">
                        <h5>Introduction</h5>
                        <p>
                            Administration, Protection, Supervision and Development of Waqf Scheme, 2013.
                        </p>
                        <h5>Distribution of Honorarium to Imams and Muazzins</h5>
                        <ul class="list-group mb-3">
                            <li class="list-group-item">Total allocation: <strong>393 crore since inception</strong></li>
                            <li class="list-group-item">Total number of Imams benefited: <strong>38,446</strong></li>
                            <li class="list-group-item">Total number of Muazzins benefited: <strong>25,486</strong></li>
                        </ul>

                        <h5>Establishment of Hostel & Education</h5>
                        <p>
                            The establishment of hostels and educational facilities is part of the development initiative under this scheme.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <h4 class="mb-4">Hostels Information</h4>
                <p>14 Hostels are running in different districts and the total number of boarders is 1431.</p>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Sl. No.</th>
                                <th scope="col">Name of the Hostel</th>
                                <th scope="col">Seat Capacity</th>
                                <th scope="col">No. of Boarders</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='text-center'>1.</td>
                                <td>Kolkata Muslim Girls’ Hostel, 43, Dilkhusa Street, Kolkata – 17.</td>
                                <td>236</td>
                                <td>236</td>
                            </tr>
                            <tr>
                                <td class='text-center'>2.</td>
                                <td>Burdwan Muslim Girls’ Hostel, 127, B.C Road, Dist. Burdwan.</td>
                                <td>95</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td class='text-center'>3.</td>
                                <td>Berhampore Muslim Girls’ Hostel, Berhampore, Dist. Murshidabad.</td>
                                <td>223</td>
                                <td>223</td>
                            </tr>
                            <tr>
                                <td class='text-center'>4.</td>
                                <td>Sayedul Hoque Muslim Girls’ Hostel, Krishangar Narohari Mukherjee Lane, Judge Court Para, Krishanagar, Dist. Nadia.</td>
                                <td>107</td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td class='text-center'>5.</td>
                                <td>Bashirhat Begum Rokeya Muslim Girls’ Hostel, Bashirhat, Dist. 24 Parganas (N).</td>
                                <td>60</td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td class='text-center'>6.</td>
                                <td>Midnapore Muslim Girls’ Hostel, Midnapore.</td>
                                <td>60</td>
                                <td>60</td>
                            </tr>
                            <tr>
                                <td class='text-center'>7.</td>
                                <td>Suri Muslim Girls Hostel, Suri, Birbhum.</td>
                                <td>84</td>
                                <td>84</td>
                            </tr>
                            <tr>
                                <td class='text-center'>8.</td>
                                <td>Maldah Muslim Girls’ Hostel, Malda, Kuttitola Lane, Dist. Malda.</td>
                                <td>56</td>
                                <td>56</td>
                            </tr>
                            <tr>
                                <td class='text-center'>9.</td>
                                <td>Islampur Muslim Girls’ Hostel, P.O. Islampur, Dist. Uttar Dinajpur.</td>
                                <td>57</td>
                                <td>57</td>
                            </tr>
                            <tr>
                                <td class='text-center'>10.</td>
                                <td>Balurghat Muslim Girls’ Hostel, Balurghat, Dist- Dakshin Dinajpur.</td>
                                <td>95</td>
                                <td>80</td>
                            </tr>
                            <tr>
                                <td class='text-center'>11.</td>
                                <td>A.K Fazlul Haque Muslim Girls’ Hostel, 31, Dilkusha Street, Kolkata-17.</td>
                                <td>120</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td class='text-center'>12.</td>
                                <td>Abdul Hakim Boys’ Waqf Hostel, 20A, Peary Mohan Roy Road, Kolkata – 27.</td>
                                <td>120</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td class='text-center'>13.</td>
                                <td>Boo-Ali Muslim Boys’ Hostel & Empowerment Centre, 1, No. Kaiser Street, Kolkata – 09.</td>
                                <td>120</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td class='text-center'>14.</td>
                                <td>Suri Muslim Boys’ Hostel, Suri, Birbhum.</td>
                                <td>30</td>
                                <td>30</td>
                            </tr>
                            <tr class="table-info">
                                <td colspan="2" class="text-end"><strong>Total</strong></td>
                                <td><strong>1463</strong></td>
                                <td><strong>1431</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container mt-5">
                <h4 class="mb-4">Disbursement of Stipend and Scholarships</h4>
                <div class="card">
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item">
                                Disbursement of stipend to the poor and meritorious students of Minority community is a regular program.
                            </li>
                            <li class="list-group-item">
                                1. At Madhyamik (or equivalent level) the amount is Rs. 3,000/- that of H.S. (or equivalent level) is Rs. 4,000/- per year.
                            </li>
                            <li class="list-group-item">
                                2. In addition Chairperson of the Board has been empowered by the Board to grant scholarship to the extent of Rs. 2,00,000/- (Two lakh) to the meritorious and deserving students.
                            </li>
                            <li class="list-group-item">
                                3. An amount of Rs. Rs. 24,85,000/- has been disbursed for the year 2016-17 up to 13.12.16 to 679 students on merit basis.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container mt-5 mb-5">
                <h4 class="mb-4 text-center">Category-wise Applications Considered</h4>
                <div class="card mb-4">
                    <div class="card-header bg-primary text-white">
                        <h5>General Category</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead class="table-dark">
                                    <tr>
                                        <th scope="col">Sl. No.</th>
                                        <th scope="col">Categories</th>
                                        <th scope="col">Percentage</th>
                                        <th scope="col">Total No.</th>
                                        <th scope="col">Rate of Amount (Rs.)</th>
                                        <th scope="col">Total Amount (Rs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class='text-center'>01.</td>
                                        <td>Madhyamik – GEN</td>
                                        <td>75% & above</td>
                                        <td>178</td>
                                        <td>3,000/-</td>
                                        <td>5,34,000</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'>02.</td>
                                        <td>Higher Secondary – GEN</td>
                                        <td>80% & above</td>
                                        <td>329</td>
                                        <td>4,000/-</td>
                                        <td>13,16,000</td>
                                    </tr>
                                    <tr class="table-info">
                                        <td colspan="4" class="text-end"><strong>GENERAL TOTAL</strong></td>
                                        <td></td>
                                        <td><strong>18,50,000</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card mb-4">
                    <div class="card-header bg-success text-white">
                        <h5>Imam & Moazzin Category</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead class="table-dark">
                                    <tr>
                                        <th scope="col">Sl. No.</th>
                                        <th scope="col">Categories</th>
                                        <th scope="col">Percentage</th>
                                        <th scope="col">Total No.</th>
                                        <th scope="col">Rate of Amount (Rs.)</th>
                                        <th scope="col">Total Amount (Rs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class='text-center'>03.</td>
                                        <td>Madhyamik – IMAM</td>
                                        <td>55% & above</td>
                                        <td>42</td>
                                        <td>3,000/-</td>
                                        <td>1,26,000</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'>04.</td>
                                        <td>Madhyamik – MOAZZIN</td>
                                        <td>55% & above</td>
                                        <td>11</td>
                                        <td>3,000/-</td>
                                        <td>33,000</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'>05.</td>
                                        <td>Higher Secondary – IMAM</td>
                                        <td>55% & above</td>
                                        <td>83</td>
                                        <td>4,000/-</td>
                                        <td>3,32,000</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'>06.</td>
                                        <td>Higher Secondary – MOAZZIN</td>
                                        <td>55% & above</td>
                                        <td>36</td>
                                        <td>4,000/-</td>
                                        <td>1,44,000</td>
                                    </tr>
                                    <tr class="table-info">
                                        <td colspan="4" class="text-end"><strong>IMAM & MOAZZIN TOTAL</strong></td>
                                        <td></td>
                                        <td><strong>6,35,000</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body text-center bg-warning">
                        <h5><strong>Grand Total</strong></h5>
                        <p><strong>Total Applications Considered:</strong> 679</p>
                        <p><strong>Total Amount Disbursed:</strong> Rs. 24,85,000/-</p>
                    </div>
                </div>
            </div>
        </>
    )
}
