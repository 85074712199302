import React from 'react'
const listOfProperty = "/Docs/notice-board/List-Application.pdf";
const notice = "/Docs/notice-board/NOTICE.pdf";

export const NoticeBoardNotification = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">GENERAL NOTICE for Enrolment as Waqf</h2>
            <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Memo No./Ref. No./Adv. No.</th>
                        <th scope="col">Click to Download</th>
                        <th scope="col">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>12.11.2024</td>
                        <td class="clickable-text"> <a target="_blank" onClick={() => handleDownload(notice)}>GENERAL NOTICE for Enrolment as Waqf</a> </td>
                        <td>Memo No.680</td>
                        <td><a target="_blank" onClick={() => handleDownload(listOfProperty)} class="btn btn-primary">List of Properties</a></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>14.08.2024</td>
                            <td>Resolutions of the Board meeting dated 14.08.2024 and 21.08.2024 are ready for delivery to the hearing parties.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>22.05.2023</td>
                            <td>Next Board meeting will be held on 31.05.2023, Wednesday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>13.03.2023</td>
                            <td>Next Board meeting will be held on 21.03.2023, Tuesday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>17.02.2023</td>
                            <td>Next Board meeting will be held on 20.02.2023, Monday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>09.02.2023</td>
                            <td>Next Board meeting will be held on 09.02.2023, Thursday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>03.01.2023</td>
                            <td>Next Board meeting will be held on 05.01.2023, Thursday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>18.11.2022</td>
                            <td>Next Board meeting will be held on 22.11.2022, Tuesday.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>06.09.2022</td>
                            <td>Next Board meeting will be held on 13.09.2022</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>23.08.2022</td>
                            <td>Next Board meeting will be held on 30.08.2022</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>04.04.2022</td>
                            <td>1st Meeting of the Reconstituted Board of Auqaf (Election for the Chairperson) will be held on 11-04-2022</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    )
}
