import React from 'react'
const listOfProperty = "/Docs/notice-board/List-Application.pdf";
const notice = "/Docs/notice-board/NOTICE.pdf";

export const NoticeWithMemoNumber = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Notice Board / Notifications</h2>

            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Memo No./Ref. No./Adv. No.</th>
                            <th scope="col">Click to Download</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>06.09.2022</td>
                            <td>Gradation List of the UDA/Accountant/Inspector/Law Inspector/Cashier-cum-Store Keeper as per sub rule 18(4)(XII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 1172/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List 1</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>06.09.2022</td>
                            <td>Gradation List of the Asst. Cashier/LDC/Auditor/Law Asst./Asst. Accountant/Amin/Surveyor/Record Keeper/Rent Collector/Telephone Operator-cum-receptionist as per sub rule 18(4)(XII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 1173/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List 2</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>06.09.2022</td>
                            <td>Gradation List of Liftman/Group ‘D’/Sweeper as per sub rule 18(4)(XXXIII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 1174/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List 3</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>06.07.2022</td>
                            <td>Final Electoral Roll for election of Member to the Board of Auqaf, W.B., 2022</td>
                            <td></td>
                            <td><a href="#" class="btn btn-primary">Click to Download</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>19.07.2022</td>
                            <td>Notice for Election of Members of the Waqf Board</td>
                            <td></td>
                            <td><a href="#" class="btn btn-primary">Click to Download</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>19.07.2022</td>
                            <td>List of Contesting Candidates</td>
                            <td></td>
                            <td><a href="#" class="btn btn-primary">Click to Download</a></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>22.04.2022</td>
                            <td>Gradation List of the UDA/Accountant/Inspector/Law Inspector/Cashier-cum-Store Keeper as per sub rule 18(4)(XII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 587/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List I</a></td>
                            <td>Objection, if any, should be submitted within 15 days.</td>
                        </tr>
                        <tr>
                            <td>22.04.2022</td>
                            <td>Gradation List of the Asst. Cashier/LDC/Auditor/Law Asst./Asst. Accountant/Amin/Surveyor/Record Keeper/Rent Collector/Telephone Operator-cum-receptionist as per sub rule 18(4)(XII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 588/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List II</a></td>
                            <td>Objection, if any, should be submitted within 15 days.</td>
                        </tr>
                        <tr>
                            <td>22.04.2022</td>
                            <td>Gradation List of Liftman/Group ‘D’/Sweeper as per sub rule 18(4)(XXXIII) and 18(7)(i) of the Waqf Regulation 2008.</td>
                            <td>Memo No. 589/1(3)</td>
                            <td><a href="#" class="btn btn-primary">Gradation List III</a></td>
                            <td>Objection, if any, should be submitted within 15 days.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    )
}
