import React from 'react'
const notice = "/Docs/hostel/NOTICE.pdf";
const hostelAdmission = "/Docs/hostel/HOSTEL-ADMISSION-FORM.pdf";
const noticeScholarship = "/Docs/download/noticeScholarship.pdf";
const formScholarship = "/Docs/download/formScholarship.pdf";
const FormStipend = "/Docs/download/Form-Stipend.pdf";
const noticeStipend = "/Docs/download/Stipend-notice.pdf";
const noticeImams = "/Docs/download/notice-Imams.pdf";
const enrollmentBengali = "/Docs/download/ENROLLMENT-APPPLICATION_BENGALI.pdf";
const enrollmentEnglish = "/Docs/download/ENROLLMENT-APPPLICATION_ENGLISH.pdf";
const statementAccount = "/Docs/download/STATEMENT-OF-ANNUAL-ACCOUNTS-Enrollment.pdf";
const rules = "/Docs/download/FORM-Enrollment.pdf";
const MandateForm = "/Docs/imam/Mandate-Form.pdf";
const NewImamsForm = "/Docs/imam/New-Form-converted.pdf";
const ChangeReplacementForm = "/Docs/imam/Change-or-Replacement-Form-converted.pdf";

export const DownloadForm = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };
    
    return (    
        <div class="container mt-5">
            <div class="card mb-4">
                <div class="card-header">
                    <h4>Hostel Admission</h4>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-outline-primary">Notice 2024 <sup class='text-danger'>New</sup> </a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(hostelAdmission)} class="btn btn-outline-primary">Form 2024 <sup class='text-danger'>New</sup></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header">
                    <h4>Scholarship Forms</h4>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(noticeScholarship)} class="btn btn-primary">Notice 2020</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(formScholarship)} class="btn btn-primary">Form 2020</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header">
                    <h4>Stipend Forms</h4>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(noticeStipend)} class="btn btn-primary">Notice 2021</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(FormStipend)} class="btn btn-primary">Form 2021</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header">
                    <h4>Enrollment and Others</h4>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(enrollmentBengali)} class="btn btn-primary">ENROLLMENT APPLICATION (BENGALI)</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(enrollmentEnglish)} class="btn btn-primary">ENROLLMENT APPLICATION (ENGLISH)</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(statementAccount)} class="btn btn-primary">STATEMENT OF ANNUAL ACCOUNTS</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(rules)} class="btn btn-primary">Rules for new Registration/Enrollment of Waqf properties</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header">
                    <h4>Imams and Muazzins Related Forms</h4>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(noticeImams)} class="btn btn-primary">Notice for Imams and Muazzins</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(NewImamsForm)} class="btn btn-primary">New Imams/Muazzins Form</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(ChangeReplacementForm)} class="btn btn-primary">Change or Replacement Form of Imams/Muazzins</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" onClick={() => handleDownload(MandateForm)} class="btn btn-primary">Mandate Form</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
