import React from 'react'
const memo293 = "/Docs/Recruitment/NOTICE-_GROUP-A-GROUP-C.pdf";
const notmemo = "/Docs/Recruitment/NOTICE-FOR-INVITING-APPLICATIONS-OF-POSTS-GR-A-GR-C.pdf";


export const RecruitmentComponent = () => {
    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };
    
    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Recruitment Information</h2>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    Walk-in-Interview for Gr. A & Gr. C (Memo No. 293 dtd. 02.02.2021)
                </div>
                <div class="card-body">
                    <p>Walk-in-Interview details for Group A and Group C positions as per Memo No. 293 dated 02.02.2021.</p>
                    <a target="_blank" onClick={() => handleDownload(memo293)} class="btn btn-info">For more details {'>>'} Click Here</a>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header bg-secondary text-white">
                    Interview & Recruitment of Posts Gr. A & C (Retd.) on Contract Basis
                </div>
                <div class="card-body">
                    <p>Interview and recruitment details for retired posts of Group A and C on a contract basis.</p>
                    <a target="_blank" onClick={() => handleDownload(notmemo)} class="btn btn-info">For more details {'>>'} Click Here</a>
                </div>
            </div>
        </div>

    )
}
