import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer class="text-center text-lg-start bg-body-tertiary text-muted">
            <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                <div class="me-5 d-none d-lg-block">
                    <span>Get connected with us on social networks:</span>
                </div>

                <div>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-google"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-linkedin"></i>
                    </a>
                </div>
            </section>

            <section class="">
                <div class="container text-center text-md-start mt-5">
                    <div class="row mt-3">
                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">
                                <i class="fas fa-gem me-3"></i>BOARD OF AUQAF
                            </h6>
                            <p>
                                West Bengal Auqaf Board is a statutory  body constituted by the Government of West Bengal under the Waqf Act 1995 (Central Act  43 of 1995).
                            </p>
                        </div>

                        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">
                                Useful Links
                            </h6>
                            <p>
                                <Link to="/imam_moazzin"><a class="text-reset text-decoration-none">Imam & Moazzin Info</a></Link>
                            </p>
                            <p>
                                <Link to="/board-of-members"> <a class="text-reset text-decoration-none">Organisation</a></Link>
                            </p>
                            <p>
                                <Link to="/recruitment"><a class="text-reset text-decoration-none">Recruitment</a></Link>
                            </p>
                            <p>
                                <Link to="/download-forms-applications"><a class="text-reset text-decoration-none">Download Forms</a></Link>
                            </p>
                            <p>
                                <Link to="/hostel"><a class="text-reset text-decoration-none">Hostel</a></Link>
                            </p>
                        </div>

                        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">
                                Gallery
                            </h6>
                            <p>
                                <Link to="/gallery"><a class="text-reset text-decoration-none">Image Gallery</a></Link>
                            </p>
                            <p>
                                <Link to="/gallery"><a  class="text-reset text-decoration-none">Video Gallery</a></Link>
                            </p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                            <p><i class="fas fa-home me-3"></i>6/2, Madan Street, Kolkata – 700 072.</p>
                            <p>
                                <i class="fas fa-envelope me-3"></i>
                                ceoboardofwakfswb@gmail.com
                            </p>
                            <p><i class="fas fa-phone me-3"></i> + 01 234 567 88(033) 2212-6431 / 6523 / 7722</p>
                        </div>
                    </div>

                </div>
            </section>

            <div class="text-center p-4">
                © 2017 Board Of Auqaf, WB. All rights reserved
            </div>

        </footer>
    )
}

export default Footer