import React from 'react'

export const NoticePhoto = () => {
    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Pathorchapuri Mazar and Guest House</h2>

            <div class="card mb-3">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        {/* Bootstrap Carousel for Images */}
                        <div id="mazarCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                {/* First Carousel Item with 3 Images */}
                                <div class="carousel-item active">
                                    <div class="d-flex justify-content-between">
                                        <img
                                            src="https://auqafboardwb.org/wp-content/uploads/2017/02/guest-house-with-contact-number.jpg"
                                            class="d-block img-thumbnail"
                                            alt="Pathorchapuri Mazar Image 1"
                                            style={{ width: '30%' }}
                                        />
                                        <img
                                            src="https://auqafboardwb.org/wp-content/uploads/2017/02/Dargah.jpg"
                                            class="d-block img-thumbnail"
                                            alt="Pathorchapuri Mazar Image 2"
                                            style={{ width: '30%' }}
                                        />
                                        <img
                                            src="https://auqafboardwb.org/wp-content/uploads/2017/02/Mazar.jpg"
                                            class="d-block img-thumbnail"
                                            alt="Pathorchapuri Mazar Image 3"
                                            style={{ width: '30%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Carousel Controls */}
                            <a class="carousel-control-prev" href="#mazarCarousel" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#mazarCarousel" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card-body">
                            <h5 class="card-title">Pathorchapuri Mazar and Guest House</h5>
                            <p class="card-text">
                                Located at Pathar Chapuri, Birbhum, this Mazar and Guest House offers a serene and spiritual
                                environment for visitors. Explore the history and enjoy a peaceful stay in the guest house.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
