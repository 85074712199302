import React from 'react'

export const InfoAugfs = () => {
    return (
        <>
            <div className="container my-5">
                <h1 className="text-center mb-4">WELCOME TO BOARD OF AUQAF, WEST BENGAL</h1>
                <p>
                    West Bengal Auqaf Board consists of elected and nominated members from various categories which are mentioned in the Waqf Act. The West Bengal Auqaf Board came into existence in the year 1936 following the Bengal “Waqf” Act, 1934.
                </p>

                <div className="row">
                    <div className="col-md-6 mb-4">
                        <h4>ABOUT WAQF</h4>
                        <h5>1.1 Waqf – the religious background:-</h5>
                        <p>
                            The word “Waqf” (Arabic: وقف‎‎) also known as habous has its origin in the Arabic word “Waqafa” meaning thereby to detain or to hold or tie up. It is said that once second Khalifa Omar acquired a piece of land in Khaibar and asked the prophet Mohammed as how best to make the most pious use of it. The prophet said “Tie up the property and devote the usufruct to the welfare of human beings, and it is not to be sold or made the subject of gift or inheritance , devote its produce to your children , your kindred and the poor and in the way of Allah”.
                        </p>
                    </div>

                    <div className="col-md-6 mb-4">
                        <h5>1.2 Waqf- the legal background:- </h5>
                        <p>
                            During the administration of Mughals in the later stage, Aurangazeb codified the Muslim Laws including the Laws on Waqf through, “Fatawai-E- Alamgiri” which was valid till the period of the Privy Council in the British age.

                            In India, the Musalman Waqf Validating Act, 1913, the Musalman Waqf Act. 1923 were in vogue in the British period during the Privy Council.

                            The Bengal Waqf Act, 1934, came into existence and thereafter , the Waqf Act, 1954 (Central Act) was in force except in West Bengal where the Bengal Waqf Act, 1934 was in force till the Promulgation of the Waqf Act, 1995 (Central Act).
                        </p>
                    </div>

                    <div className="col-md-6 mb-4">
                        <h5>1.3 Waqf- what it is ?</h5>
                        <p>
                            The Waqf Act 1995 defines the Waqf as the permanent dedication by any person of movable or immovable property for any purpose recognized by the Muslim law as pious, religious or charitable. The properties so dedicated are called Waqf .The person who so dedicates his or her property is known as Waqif and the person who manages such property is known as Mutawalli (manager and administrator).

                            Waqfs are of three kinds. (i) Waqfullillah i.e. other than waqf-al-al-aulad  (i.e. Public Waqf), (ii) Waqf-al-al-aulad (Waqf for children) & (iii) Waqf by user such as Mosque, Madrasah, Dargah, Graveyard, Piroshtan, Idgah etc.

                            The public waqf is meant for religious and charitable purposes covering the public at large and Waqf-al-al-aulad is a waqf meant for welfare of one’s children/relatives. Waqf by user means where any piece of land or portion of building has been used permanently for any religious or pious purpose example Mosque, Madrasah, etc.
                        </p>
                    </div>

                    {/* <div className="col-md-6 mb-4">
                    <h5>Types of Waqf</h5>
                    <ul>
                        <li><strong>Waqfullillah:</strong> Public Waqf meant for religious and charitable purposes.</li>
                        <li><strong>Waqf-al-al-aulad:</strong> Waqf meant for the welfare of one’s children/relatives.</li>
                        <li><strong>Waqf by user:</strong> Includes Mosques, Madrasahs, Dargahs, Graveyards, etc.</li>
                    </ul>
                </div> */}

                    <div className="col-md-6 mb-4">
                        <h5>Who is Mutawalli?</h5>
                        <p>
                            A person who manages the Waqf property is called "Mutawalli". The legal position of Mutawalli is that of the Manager or Superintendent of the Waqf properties.

                            Mutawallis are again of two categories; appointed and recorded. The Mutawallis recorded as per version of the deed of conveyance, executed by Waqif having specific rule of succession are called recorded ones and in other cases where there is no specific rule of succession or having no waqf deed Mutawalli is appointed by the Board for a certain period.
                        </p>
                    </div>

                    <div className="col-md-6 mb-4">
                        <h5>Who is Waqif or Donor?</h5>
                        <p>
                            Waqif or Donor means any person making dedication of any movable or immovable property  in the name of the Almighty for any purpose recognised  by the Muslim Law as pious, religious or charitable. Once a property is dedicated the Donor loses his entire rights and such property can never be alienated, mortgaged or transferred.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <h2 className="text-center mb-4">Our Achievements</h2>
                <p className="mb-4">
                    The Board of Auqaf, West Bengal has taken steps for better and more organized administration of waqf in the State in the last years. Some of the very important ones are as follows:
                </p>

                {/* Augmentation of Income Section */}
                <div className="mb-5">
                    <h4>1. Augmentation of Income from Big Properties</h4>
                    <p>
                        Cases of valuable Waqf properties were taken up in the right earnest and the issues have been resolved, boosting the income of the Waqf Estate and Waqf fund.
                    </p>

                    <div className="row">
                        {/* Property 1 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Prince GholamMohd. Waqf Estate (E.C. NO. 4775)</h5>
                                    <h6>Shaw Wallace Building at 4&5, Banshall St., Kolkata:-</h6>
                                    <p>
                                        As per compromise settlement in 2008 M/S Shaw Wallace Company Pvt. Ltd. is paying at present Rs.6,00,000/- per month to the Waqf Estate. A lump sum of Rs. One crore as one time contribution was finalized and paid at the time of execution of settlement. Earlier they used to pay Rs. 32,000/- per month.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Property 2 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Royal Calcutta Golf Club:-</h6>
                                    <p>
                                        As per compromise settlement, Royal Calcutta Golf Club, Kolkata is paying  Rs.1,00,000/- per month as rent to the Waqf Estate and have paid a lump sum donation of Rs.38,00,000/- . The settlement has already been executed.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Property 3 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Tollygunge Club:-</h6>
                                    <p>
                                        Tollygunge Club, measuring about 305 bighas of land. The properties had been leased out by the then mutawalli to Tollygunge Club. Now as per the order  of Hon’ble High Court,Calcutta it has been settled at  a monthly rent of Rs  1,00,000/- and one time donation of Rs. 32,00,000/- and the settlement agreement has been recently executed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Development of Waqf Properties Section */}
                <div className="mb-5">
                    <h4>2. Development of Waqf Properties</h4>
                    <p>
                        The Board of Waqf has taken up developmental works in a big way. Some of the important ones are as follows:-
                    </p>

                    <div className="row">
                        {/* Project 1 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Guest House under E.C. No 1040  Munshi Abdur Rauf Waqf Estate.</h6>
                                    <p>
                                        The construction work of one guest house at 9A, Lower Range with an estimated cost of Rs 18 lakh has been completed by the executive agency, M/S Mackintosh Burn Ltd and it will be operationalised very soon.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Project 2 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Construction of A. K. FazlulHaque Muslim Girls’ Hostel at 31, Dilkhusa Street, Kolkata-17:-</h6>
                                    <p>
                                        This is a four-storied 2nd Muslim Girls’ Hostel at Kolkata, with accommodation capacity for 100   students. At an outlay of Rs.1.77 crores, the Hostel has been constructed by the P W  ( S S ),Govt of West Bengal. Hostel has been inaugurated by Hon’ble Chief Minister, West Bengal, MAMATA BENERJEE on 28.01.2016 in presence of Hon’ble Chairperson, Board of Auqaf, W.B.   Muslim Girls education has noticed a plausible progress in last several years. A host of Muslim girl students, poor but meritorious, has been undertaking higher education in esteemed institutions in Kolkata with sole aim to be financially independent, socially and culturally conscious. Of late, there was only one such accommodation available in Kolkata for poor and meritorious Muslim Girls from across the corners of the State, which can hardly cope with the ever increasing demand. With the completion of this Hostel, another lot of 100 Muslim Girls would be able to pursue higher education in Kolkata and become financially independent for the greater interest of the Muslim community at large.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Project 3 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Construction of Boo Ali Muslim Boys’ Hostel –Cum- Empowerment Centre at 1, Kaiser Street,  (Sealdah) Kolkata -700009 : –</h6>
                                    <p>
                                        The construction work of the building with an estimated cost of Rs 8.55 crores has already been started in October, 2009. Block- A has been completed.  An amount of Rs 2.70 crores has already been paid to the executive agency, M/S Mackintosh Burn Ltd. The hostel has a total seat capacity of 110 students. Block ‘A’ has been  inaugurated  by Hon’ble Chief Minister, West Bengal,  MAMATA BANERJEE  on 1ST March 2014 in presence of Hon’ble Chairperson, Board of Auqaf, W.B.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Project 4 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6> Construction for the development of the Waqf Property at premises no. 4 & 5, St. Georges Gate Road, Kolkata :-</h6>
                                    <p>
                                        The Board of Auqaf, W.B. has made an agreement with Gloria Builders for the  development& renovation  of the said Waqf Estate with certain terms & conditions. Such innovative developments will fetch more income to Board of Auqaf, W.B. as well as uplift the structural condition of the Waqf Estate.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Project 5 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6> Development of Nazimunnessa Begum Waqf Estate vide E.C. No. 3776 at 45, Beniapukur Road, Kolkata- 14 :–</h6>
                                    <p>
                                        The matter is within the active consideration of the Board of Auqaf, W.B., Due process is being observed. Preliminary steps have been taken to proceed & permission for development of the said premises has been accorded by the Board of Auqaf, W.B. The Developer has submitted the Building Sanction Plan to KMC. The development of the premises will be beneficial in many ways.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Project 6 */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Development of ZainabBai Haji JoosubWaqf Estate vide E.C. No. 4983 at 4C, Aucland Square, Kolkata- 17:-</h6>
                                    <p>
                                        The matter is within the active consideration of the Board of Auqaf, W.B., Due process is being observed. The Board of Auqaf, W.B., has given permission to develop the premises with certain terms and conditions. The Developer has submitted the Building Sanction Plan to K.M.C.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                {/* Creation of Corpus Fund */}
                <div className="mb-5">
                    <h4>3. Creation of Corpus Fund of Rs. One Crore for Stipend</h4>
                    <p>
                        The Board of Auqaf, W.B., has created a corpus fund of  Rs one crore for  the purpose  with a grant of Rs 50 lakh from the State Govt and 50 lakh from its own  resources. The Board will provide fund for stipend to the tune of Rs 10 Lakh annually with preference to the wards of Imams and Moezzins
                    </p>
                </div>

                {/* New Initiatives Section */}
                <div className="mb-5">
                    <h4>4. New Initiatives</h4>

                    <div className="row">
                        {/* Initiative A */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Construction Projects</h6>
                                    <ul>
                                        <li>Community Hall on Plot No-2184, area 0.58 acre.</li>
                                        <li>Housing for Destitute Women on Plot No-2185, area 0.79 acre.</li>
                                        <li>Muslim Girls’ Hostel in Hooghly, Plot No-2149, under Charghata Waqf Estate.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Initiative B */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Educational Institutions</h6>
                                    <ul>
                                        <li>Plans for Nursing, D.Ed, B.Ed, and Law College in Hedayat Khan Waqf Estate, Howrah.</li>
                                        <li>Rs. 7,63,158/- compensation for Bargadar rehabilitation sent to D.M, Howrah.</li>
                                        <li>Boundary wall construction and approvals are in process.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Initiative C */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Waqf Promotion & Awareness</h6>
                                    <p>
                                        The Board has organized 45 awareness camps across different districts in West Bengal in the last two years to promote Waqf matters.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Initiative D */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Shopping Arcades around Data Baba Mazar, Patherchapuri, Birbhum</h6>
                                    <ul>
                                        <li>Project Cost: Rs. 7 Crores from the Waqf Estate fund.</li>
                                        <li>225 shops to be constructed, work in progress through Birbhum Zilla Parishad.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Initiative E */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Lok Adalat for Dispute Settlement</h6>
                                    <ul>
                                        <li>Eight Lok Adalats organized to settle rent and related disputes.</li>
                                        <li>209 cases settled, Rs. 23,47,188 recovered, with a total settled amount of approx Rs. 78 lakh.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Initiative F */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6>Construction Projects in Malda</h6>
                                    <ul>
                                        <li>Minority Bhavan construction completed.</li>
                                        <li>Construction of an English Medium Madrasa in progress.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
