import React from 'react'
import { Link } from 'react-router-dom'
const FormStipend = "/Docs/download/Form-Stipend.pdf";
const notice = "/Docs/hostel/NOTICE.pdf";

export const NoticeMoreInformation = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Information & Downloads</h2>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="card-title">Imam & Moazzin Information</h5>
                    <p class="card-text">Click the button below for more information on Imams and Moazzins.</p>
                    <Link to={'/imam_moazzin'}><a href="#" class="btn btn-primary">Click Here</a></Link>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="card-title">Hostel Admission 2021</h5>
                    <p class="card-text">Hostel Admission in Kolkata under Auqaf Board, W.B.</p>
                    <div class="d-flex gap-2 justify-content-between">
                        <Link to={'/hostel'} class="btn btn-info">For more details</Link>
                        <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-success">Download Form</a>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-body">
                    <h5 class="card-title">Scholarship 2021-22</h5>
                    <p class="card-text">Application for Scholarship of Muslim Students for the Financial Year 2021-22.</p>
                    <div class="d-flex gap-2 justify-content-between">
                        <Link to={'/stipend'} class="btn btn-info">For more details</Link>
                        <a target="_blank" onClick={() => handleDownload(FormStipend)} class="btn btn-success">Download Form</a>
                    </div>
                </div>
            </div>
        </div>

    )
}
