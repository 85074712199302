import "../App.css";
import React, { useEffect } from 'react';
import { TopNavbar } from '../Componants/TopNavbar'
import { Navbar } from '../Componants/Navbar'
import Footer from '../Componants/Footer'
const waqfAct1995 = "/Docs/Waqf-Rules/waqfAct1995.pdf";
const wbWaqfRules2022 = "/Docs/Waqf-Rules/wbWaqfRules2022.pdf";
const wbWaqfRules2001 = "/Docs/Waqf-Rules/wbWaqfRules2001.pdf";
const wbWaqfRegulations2008 = "/Docs/Waqf-Rules/wbWaqfRegulations2008.pdf";


export const AuqafActRules = () => {
    const formUrls = {
        waqfAct1995: waqfAct1995,
        wbWaqfRules2022: wbWaqfRules2022,
        wbWaqfRules2001: wbWaqfRules2001,
        wbWaqfRegulations2008: wbWaqfRegulations2008
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <TopNavbar />
            <div className="container-fluid position-relative p-0">
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn" style={{ fontSize: '35px' }}>Waqf Act, Rules and Regulations</h1>
                            <a className="h6 text-white">Home</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <h2 className="text-center mb-4">Waqf Act, Rules, and Regulations</h2>
                <p className="text-center mb-4">
                    Click on the buttons below to access the relevant forms for each Waqf Act, rule, or regulation.
                </p>

                {/* Responsive Buttons for Waqf Acts */}
                <div className="row justify-content-center">
                    <div className="col-md-8 d-flex flex-column gap-3">
                        {/* Waqf Act 1995 (As amended in 2013) */}
                        <a
                            href={formUrls.waqfAct1995}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary"
                        >
                            Waqf Act 1995 (As amended in 2013)
                        </a>

                        {/* W.B. Waqf Rules 2022 */}
                        <a
                            href={formUrls.wbWaqfRules2022}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-success"
                        >
                            W.B. Waqf Rules 2022
                        </a>

                        {/* W.B. Waqf Rules 2001 */}
                        <a
                            href={formUrls.wbWaqfRules2001}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-info"
                        >
                            W.B. Waqf Rules 2001
                        </a>

                        {/* W.B. Waqf Regulations 2008 */}
                        <a
                            href={formUrls.wbWaqfRegulations2008}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-warning"
                        >
                            W.B. Waqf Regulations 2008
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
