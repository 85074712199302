import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import slide1 from "../images/gal17.jpg"
import slide2 from "../images/gal12.jpg"
import slide3 from "../images/2.jpg"
import slide4 from "../images/1.jpg"

const items = [
    {
        img: slide1,
    },
    {
        img: slide2,
    },
    {
        img: slide3,
    },
    {
        img: slide4,
    },

];

export const CarouselK = () => {
    return (
        <Carousel data-bs-theme="dark">
            {items.map((testimonial, index) => (
                <Carousel.Item key={index}>
                    <div className='container'>
                        <div className='row mt-2 mb-2'>
                            <div className='col' style={{ textAlign: 'center' }}>
                                <img className="w-100 h-100" src={testimonial.img} alt={testimonial.title} />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
