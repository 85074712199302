import React from 'react'

export const Organisations = () => {
    return (
        <>

            <div class="container my-5">
                <h2 class="text-center mb-4">Constitution of Board</h2>
                <p class="text-center">Terms of the Board start w.e.f. 11th April, 2022</p>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col" class='text-center'>Sl. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>1.</th>
                                <td class='table-bg-clr'>Hon’ble Justice Sahidullah Munshi (Retd.)</td>
                                <td class='table-bg-clr'>Chairperson</td>
                            </tr>
                            <tr>
                                <th scope="row"  class='text-center table-bg-clr'>2.</th>
                                <td class='table-bg-clr'>Jb. Khalilur Rahaman – Hon’ble MP (Lok Sabha)</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row"  class='text-center table-bg-clr'>3.</th>
                                <td class='table-bg-clr'>Jb. Md. Nadimul Haque – Hon’ble MP (Rajya Sabha)</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>4.</th>
                                <td class='table-bg-clr'>Jb. Jakir Hossain – Hon’ble MLA</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>5.</th>
                                <td class='table-bg-clr'>Jb. Mosaraf Hossain – Hon’ble MLA</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>6.</th>
                                <td class='table-bg-clr'>Jb. Nasiruddin Ahamed - Member of West Bengal Bar Council</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>7.</th>
                                <td class='table-bg-clr'>Jb. Affakuzzaman Khan – Mutawalli Representative</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>8.</th>
                                <td class='table-bg-clr'>Jb. Qari Fazlur Rahman – Scholar in Sunni Islamic Theology</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>9.</th>
                                <td class='table-bg-clr'>Jb. Syed Meher Abbas Rizvi – Scholar in Shia Islamic Theology</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>10.</th>
                                <td class='table-bg-clr'>Mohtarma Shama Parveen – IAS</td>
                                <td class='table-bg-clr'>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='table-bg-clr'>Ahsan Ali, WBCS (Exe.)</td>
                                <td class='table-bg-clr'>Chief Executive Officer & Ex-Officio Secretary of the Board</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container my-5">
                <h2 class="text-center mb-4">Other Officials</h2>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col" class='text-center'>Sl. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>1.</th>
                                <td class='table-bg-clr'>Kushal Bose - WBA & AS</td>
                                <td class='table-bg-clr'>Accounts Officer</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>2.</th>
                                <td class='table-bg-clr'>Sultan Shah Alam - WBLS</td>
                                <td class='table-bg-clr'>Law Officer</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>3.</th>
                                <td class='table-bg-clr'>Wasif Mohammad Samid – WBSLRS, Gr.-I</td>
                                <td class='table-bg-clr'>Revenue Officer</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>4.</th>
                                <td class='table-bg-clr'>Md Rajibur Rahaman, WBSLRS, Gr.-I</td>
                                <td class='table-bg-clr'>Revenue Officer</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container my-5">
                <h2 class="text-center mb-4">Former Commissioners, Board of Auqaf</h2>
                <h5 class="text-center mb-4">West Bengal</h5>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col" class='text-center'>Sl. No.</th>
                                <th scope="col">Names</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>1.</th>
                                <td class='table-bg-clr'>Khan Bahadur M.A. Momen – CIE</td>
                                <td class='table-bg-clr'>02.03.1936</td>
                                <td class='table-bg-clr'>03.07.1939</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>2.</th>
                                <td class='table-bg-clr'>Nawabzada A.F.M. Abul Ali</td>
                                <td class='table-bg-clr'>02.08.1939</td>
                                <td class='table-bg-clr'>15.11.1939</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>3.</th>
                                <td class='table-bg-clr'>Khan Bahadur M.A. Momen – CIE</td>
                                <td class='table-bg-clr'>15.11.1939</td>
                                <td class='table-bg-clr'>26.04.1940</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>4.</th>
                                <td class='table-bg-clr'>Mr. Khwaja Shabuddin – CBE</td>
                                <td class='table-bg-clr'>26.04.1940</td>
                                <td class='table-bg-clr'>12.05.1941</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>5.</th>
                                <td class='table-bg-clr'>Khan Bahadur A.H.M Wazir Ali – IAS</td>
                                <td class='table-bg-clr'>12.05.1941</td>
                                <td class='table-bg-clr'>11.08.1946</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>6.</th>
                                <td class='table-bg-clr'>Khan Sahib Maulavi Md Israil - MA, BL, MLC</td>
                                <td class='table-bg-clr'>11.08.1946</td>
                                <td class='table-bg-clr'>14.08.1947</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>7.</th>
                                <td class='table-bg-clr'>Janab Ahmed Hossain - BCS</td>
                                <td class='table-bg-clr'>14.08.1947</td>
                                <td class='table-bg-clr'>22.06.1948</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>8.</th>
                                <td class='table-bg-clr'>Janab Hrahman Choudhury – BL</td>
                                <td class='table-bg-clr'>22.06.1948</td>
                                <td class='table-bg-clr'>01.09.1956</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>9.</th>
                                <td class='table-bg-clr'>Janab Gholam Hamidur Rahman – BL</td>
                                <td class='table-bg-clr'>01.09.1956</td>
                                <td class='table-bg-clr'>19.07.1957</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>10.</th>
                                <td class='table-bg-clr'>Janab S.M. Murshed - IAS (Retd.)</td>
                                <td class='table-bg-clr'>19.07.1957</td>
                                <td class='table-bg-clr'>16.12.1960</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>11.</th>
                                <td class='table-bg-clr'>Janab S.M. Murshed  - IAS (Retd.)</td>
                                <td class='table-bg-clr'>16.12.1961</td>
                                <td class='table-bg-clr'>20.07.1962</td>
                            </tr>
                            <tr> 
                                <th scope="row" class='text-center table-bg-clr'>12.</th>
                                <td class='table-bg-clr'>Janab Abdus Sattar -  BA, LLB </td>
                                <td class='table-bg-clr'>20.07.1962</td>
                                <td class='table-bg-clr'>20.07.1965</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>13.</th>
                                <td class='table-bg-clr'>Janab Imaduddin Choudhury, WBCS(Exe.)</td>
                                <td class='table-bg-clr'>20.07.1965</td>
                                <td class='table-bg-clr'>02.09.1968</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>14.</th>
                                <td class='table-bg-clr'>Janb M. Rahaman - WBCS(Exe.)</td>
                                <td class='table-bg-clr'>02.09.1968</td>
                                <td class='table-bg-clr'>01.09.1971</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>15.</th>
                                <td class='table-bg-clr'>Janab  Md Alauddin - WBCS(Exe.)</td>
                                <td class='table-bg-clr'>14.09.1971</td>
                                <td class='table-bg-clr'>21.02.1972</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>16.</th>
                                <td class='table-bg-clr'>Janab Abdur Razzaque</td>
                                <td class='table-bg-clr'>21.02.1972</td>
                                <td class='table-bg-clr'>31.12.1975</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>17.</th>
                                <td class='table-bg-clr'>Janab Imaduddin Choudhury - WBCS(Exe.)</td>
                                <td class='table-bg-clr'>01.01.1976</td>
                                <td class='table-bg-clr'>30.07.1979</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>18.</th>
                                <td class='table-bg-clr'>Janab  Md Alauddin  - WBCS(Exe.)</td>
                                <td class='table-bg-clr'>21.08.1979</td>
                                <td class='table-bg-clr'>22.09.1983</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>19.</th>
                                <td class='table-bg-clr'>Janab Choudhury Abdur Rahim -  WBHJS</td>
                                <td class='table-bg-clr'>23.09.1983</td>
                                <td class='table-bg-clr'>16.11.1985</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>20.</th>
                                <td class='table-bg-clr'>Janab Asadur Rahaman - (Ex-Secretary, WBLA)</td>
                                <td class='table-bg-clr'>01.01.1986</td>
                                <td class='table-bg-clr'>26.02.1992</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>21.</th>
                                <td class='table-bg-clr'>Janab Md Abdul Hai - WBHJS </td>
                                <td class='table-bg-clr'>26.02.1992</td>
                                <td class='table-bg-clr'>31.08.1995</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>22.</th>
                                <td class='table-bg-clr'>Janab Md Samiuddin - MA, WBCS(Exe.)</td>
                                <td class='table-bg-clr'>31.08.1995</td>
                                <td class='table-bg-clr'>31.04.1996</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container my-5">
                <h2 class="text-center mb-4">Chairperson, Board of Auqaf</h2>
                <h5 class="text-center mb-4">West Bengal</h5>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col" class='text-center'>Sl. No.</th>
                                <th scope="col">Names</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>1.</th>
                                <td class='table-bg-clr'>Jb. A.M.K. Masoomi</td>
                                <td class='table-bg-clr'>29.10.1996</td>
                                <td class='table-bg-clr'>26.11.2001</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>2.</th>
                                <td class='table-bg-clr'>Jb. Hannan Mollah - MP</td>
                                <td class='table-bg-clr'>27.11.2001</td>
                                <td class='table-bg-clr'>06.02.2002</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>3.</th>
                                <td class='table-bg-clr'>Jb. Kazi Safiullah - Adv.</td>
                                <td class='table-bg-clr'>07.02.2004</td>
                                <td class='table-bg-clr'>11.10.2004</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>4.</th>
                                <td class='table-bg-clr'>Jb. Hannan Mollah - MP</td>
                                <td class='table-bg-clr'>12.10.2004</td>
                                <td class='table-bg-clr'>24.02.2006</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>5.</th>
                                <td class='table-bg-clr'>Dr. Abdus Sattar – MOS, MA & ME</td>
                                <td class='table-bg-clr'>30.10.2006</td>
                                <td class='table-bg-clr'>05.09.2008</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>6.</th>
                                <td class='table-bg-clr'>Md Nizam Shamim – IPS (Retd.)</td>
                                <td class='table-bg-clr'>05.09.2008</td>
                                <td class='table-bg-clr'>29.10.2011</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>7.</th>
                                <td class='table-bg-clr'>Hon’ble Justice Md. Abdul Ghani (Retd.)</td>
                                <td class='table-bg-clr'>24.01.2012</td>
                                <td class='table-bg-clr'>24.06.2024</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>8.</th>
                                <td class='table-bg-clr'>Hon’ble Justice Sahidullah Munshi (Retd.)</td>
                                <td class='table-bg-clr'>19.07.2024</td>
                                <td class='table-bg-clr'>--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container my-5">
                <h2 class="text-center mb-4">CHIEF EXECUTIVE OFFICER'S,  BOARD OF AUQAF </h2>
                <h5 class="text-center mb-4">West Bengal</h5>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-left">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col" class='text-center'>Sl. No.</th>
                                <th scope="col">Names</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>1.</th>
                                <td class='table-bg-clr'>Md Samiuddin - MA, WBCS (Exe.) </td>
                                <td class='table-bg-clr'>04.04.1996</td>
                                <td class='table-bg-clr'>02.06.1997</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>2.</th>
                                <td class='table-bg-clr'>Sk Mosayqr Hossain - WBCS (Exe.) </td>
                                <td class='table-bg-clr'>02.06.1997</td>
                                <td class='table-bg-clr'>08.09.2000</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>3.</th>
                                <td class='table-bg-clr'>Nazrul Islam Molla -  WBCS (Exe.) </td>
                                <td class='table-bg-clr'>08.09.2000</td>
                                <td class='table-bg-clr'>31.05.2002</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>4.</th>
                                <td class='table-bg-clr'>Abdul Matin -  WBCS (Exe.) </td>
                                <td class='table-bg-clr'>31.05.2002</td>
                                <td class='table-bg-clr'>30.06.2004</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>5.</th>
                                <td class='table-bg-clr'>Md Nurul Absar - WBCS (Exe.) </td>
                                <td class='table-bg-clr'>30.06.2004</td>
                                <td class='table-bg-clr'>01.04.2005</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>6.</th>
                                <td class='table-bg-clr'>G.H.Obaidur Rahman -  WBCS (Exe.) </td>
                                <td class='table-bg-clr'>01.04.2005</td>
                                <td class='table-bg-clr'>28.02.2007</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>7.</th>
                                <td class='table-bg-clr'>Arshad Hasan Warsi - WBCS (Exe.) </td>
                                <td class='table-bg-clr'>28.02.2007</td>
                                <td class='table-bg-clr'>01.06.2007</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>8.</th>
                                <td class='table-bg-clr'>Arfan Ali Biswas - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>01.06.2007</td>
                                <td class='table-bg-clr'>13.07.2009</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>9.</th>
                                <td class='table-bg-clr'>Syed Sarfaraz Ahmed - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>13.07.2009</td>
                                <td class='table-bg-clr'>30.09.2010</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>10.</th>
                                <td class='table-bg-clr'>Md Abdul Gani - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>30.09.2010</td>
                                <td class='table-bg-clr'>14.09.2012</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>11.</th>
                                <td class='table-bg-clr'>Humayun Biswas  - WBCS (Exe.) (I/C)</td>
                                <td class='table-bg-clr'>14.09.2012</td>
                                <td class='table-bg-clr'>05.01.2015</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>12.</th>
                                <td class='table-bg-clr'>Afzal Ahmed - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>05.01.2015</td>
                                <td class='table-bg-clr'>14.09.2015</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>13.</th>
                                <td class='table-bg-clr'>Humayun Biswas - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>14.09.2015</td>
                                <td class='table-bg-clr'>11.09.2017</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>14.</th>
                                <td class='table-bg-clr'>Mufti Samim Sawkat -  WBCS (Exe.)  </td>
                                <td class='table-bg-clr'>11.09.2017</td>
                                <td class='table-bg-clr'>07.06.2018</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>15.</th>
                                <td class='table-bg-clr'>Maniruddin Faruki - WBCS (Exe.) (I/C)</td>
                                <td class='table-bg-clr'>08.06.2018</td>
                                <td class='table-bg-clr'>18.06.2018</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>16.</th>
                                <td class='table-bg-clr'>Mufti Samim Sawkat - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>19.06.2018</td>
                                <td class='table-bg-clr'>18.02.2019</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>17.</th>
                                <td class='table-bg-clr'>Maniruddin Faruki - WBCS (Exe.) (I/C)</td>
                                <td class='table-bg-clr'>19.02.2019</td>
                                <td class='table-bg-clr'>26.06.2019</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>18.</th>
                                <td class='table-bg-clr'>Md Khalilur Rahaman -  ACEO (I/C)</td>
                                <td class='table-bg-clr'>27.06.2019</td>
                                <td class='table-bg-clr'>08.07.2019</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>19.</th>
                                <td class='table-bg-clr'>Smt Nuzhat Zainab - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>09.07.2019</td>
                                <td class='table-bg-clr'>05.08.2019</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>20.</th>
                                <td class='table-bg-clr'>Naved Akhter - WBCS (Exe.)</td>
                                <td class='table-bg-clr'>06.08.2019</td>
                                <td class='table-bg-clr'>23.02.2022</td>
                            </tr>
                            <tr>
                                <th scope="row" class='text-center table-bg-clr'>21.</th>
                                <td class='table-bg-clr'>Ahsan Ali, WBCS (Exe.)</td>
                                <td class='table-bg-clr'>23.02.2022</td>
                                <td class='table-bg-clr'>--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}
