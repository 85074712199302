import "../App.css";
import React, { useEffect } from 'react';
import { TopNavbar } from '../Componants/TopNavbar'
import { Navbar } from '../Componants/Navbar'
import Footer from '../Componants/Footer'
import { WelfareSchemes } from "../Componants/WelfareSchemes";

export const WelfareScheme = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <TopNavbar />
            <div className="container-fluid position-relative p-0">
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn" style={{ fontSize: '35px' }}>Welfare Scheme</h1>
                            <a className="h6 text-white">Home</a>
                        </div>
                    </div>
                </div>
            </div>
            <WelfareSchemes />
            <Footer />
        </div>
    )
}
