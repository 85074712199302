import React, { useState } from 'react'
const lifeCertificateform = "/Docs/imam/Life-Certificate-Form-modified-in-2023.pdf";
const PROTIBEDAN = "/Docs/imam/PROTIBEDAN-Form-modified-in-2023.pdf";
const BengaliForm = "/Docs/imam/Bengali-Form-of-Modified-Life-Certificate.pdf";
const EnglishForm = "/Docs/imam/English-Form-of-Modified-Life-Certificate.pdf";
const MandateForm = "/Docs/imam/Mandate-Form.pdf";
const NewImamsForm = "/Docs/imam/New-Form-converted.pdf";
const ChangeReplacementForm = "/Docs/imam/Change-or-Replacement-Form-converted.pdf";

export const Imam = () => {

    const [isVisible, setisVisible] = useState(false);

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Imam & Moazzin Information</h2>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    New: (16.01.2023)
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <a target="_blank" onClick={() => handleDownload(lifeCertificateform)} class="text-decoration-none btn btn-outline-primary">Life Certificate of Imam/Moazzin 2023</a>
                        </li>
                        <li>
                            <a target="_blank" onClick={() => handleDownload(PROTIBEDAN)} class="text-decoration-none btn btn-outline-primary mt-2">Protibedan</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header bg-secondary text-white">
                    Old: (03.12.2021)
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <a target="_blank" onClick={() => handleDownload(BengaliForm)} class="text-decoration-none btn btn-outline-dark">Life Certificate Bengali</a>
                        </li>
                        <li>
                            <a target="_blank" onClick={() => handleDownload(EnglishForm)} class="text-decoration-none btn btn-outline-dark mt-2">Life Certificate English</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="text-center">
                <button className="btn mb-3 btn-info" onClick={() => setisVisible(!isVisible)}>
                    For more details &gt;&gt; Click Here
                </button>
            </div>
            {
                isVisible && (
                    <div class="card mb-3">
                        <div class="card-header bg-primary text-white">
                            New form & Replacement form for Imam/Moazzin
                        </div>
                        <div class="card-body">
                            <ul class="list-unstyled">
                                <li>
                                    <a target="_blank" onClick={() => handleDownload(MandateForm)} class="text-decoration-none btn btn-outline-primary">Mandate Form</a>
                                </li>
                                <li>
                                    <a target="_blank" onClick={() => handleDownload(NewImamsForm)} class="text-decoration-none btn btn-outline-primary mt-2">New Imams/Muazzins Form</a>
                                </li>
                                <li>
                                    <a target="_blank" onClick={() => handleDownload(ChangeReplacementForm)} class="text-decoration-none btn btn-outline-primary mt-2">Change or Replacement Form</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
