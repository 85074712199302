import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import "../App.css";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "../App.css"
import { TopNavbar } from '../Componants/TopNavbar';
import { Navbar } from '../Componants/Navbar';
import Footer from "../Componants/Footer";
import { Contactus } from "../Componants/Contactus"
import { CarouselK } from "../Componants/CarouselK";
import { Link } from 'react-router-dom';
import admin from "../images/admin.jpeg"
import ceo from "../images/CEO.jpeg"
import one from "../images/gal16.jpg"
import adv1 from "../images/oneKh.png"
import adv2 from "../images/twoKh.jpg"
import adv3 from "../images/threeKh.jpg"
import left from "../images/leftkh.jpg"
const notice = "/Docs/hostel/NOTICE.pdf";
const hostelAdmission = "/Docs/hostel/HOSTEL-ADMISSION-FORM.pdf";
gsap.registerPlugin(ScrollTrigger);

export const Home = () => {

    const imageRef = useRef(null);
    const imageRef0 = useRef(null);
    const heading = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            imageRef.current,
            { scale: 0.8 },
            {
                scale: 1,
                scrollTrigger: {
                    trigger: imageRef.current,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: 1,
                },
            }
        );
        gsap.fromTo(
            imageRef0.current,
            { scale: 0.8 },
            {
                scale: 1,
                scrollTrigger: {
                    trigger: imageRef0.current,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: 1,
                },
            }
        );
        gsap.fromTo(
            heading.current,
            { scale: .9 },
            {
                scale: 1,
                duration: 2,  
                ease: 'power2.out',  
                scrollTrigger: {
                    trigger: heading.current,
                    start: 'top 80%',  
                    end: 'top 20%',  
                    scrub: 1,  
                },
            }
        );


    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".khalid p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".khalid span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".khalid", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".khalid2 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".khalid2 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".khalid2", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".khalid3 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".khalid3 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".khalid3", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".khalid4 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".khalid4 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".khalid4", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };



    return (
        <div>
            <TopNavbar />
            <div class="container-fluid position-relative p-0">
                <Navbar />
                <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="w-100" src={one} alt="" />
                            <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div class="p-3" style={{ maxWidth: '900px' }}>
                                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">WELCOME TO</h5>
                                    <h1 class="display-4 text-white mb-md-4 animated zoomIn">BOARD OF AUQAF </h1>
                                    <h1 class="display-4 text-white mb-md-4 animated zoomIn">WEST BENGAL</h1>
                                    <Link to="/about" class="btn py-md-3 px-md-5 me-3 rounded-pill quote_btn animated slideInLeft">Know More</Link>
                                    <Link to="/contact" class="btn btn-outline-light py-md-3 px-md-5 animated quote_btn1 border rounded-pill slideInRight">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="w-100" src={one} alt="" />
                            <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div class="p-3 " style={{ maxWidth: '900px' }}>
                                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">اوقاف کے</h5>
                                    <h1 class="display-4 text-white mb-md-4 animated twotext zoomIn">بورڈ میں خوش آمدید، مغربی بنگال</h1>
                                    <h1 class="display-4 text-white mb-md-4 animated twotext  zoomIn"> مغربی بنگال</h1>
                                    <Link to="/about" class="btn py-md-3 px-md-5 me-3 rounded-pill quote_btn animated slideInLeft">Know More</Link>
                                    <Link to="/contact" class="btn btn-outline-light py-md-3 px-md-5 animated quote_btn1 border rounded-pill slideInRight">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            {/* -----------------update by khalid 05/10/24----------------     */}

            <div className="container-fluid" style={{ backgroundColor: '#ccc' }}>
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-3 d-flex align-items-center justify-content-center">
                            <div className="position-relative d-flex align-items-center justify-content-center " style={{cursor:'pointer', width: '120px', height: '120px' }}>
                                <i className="fa fa-star staricon" style={{ fontSize: '100px', color: '#FFD700' }}></i>

                                <div className="position-absolute top-50 start-50 translate-middle text-center" style={{ color: '#000', fontWeight: 'bold', fontSize: '10px', width: '80px', lineHeight: '1.2' }}>
                                    <span target="_blank" onClick={() => handleDownload(notice)} className='khalidd'>Last date of submission <br /> 01.10.24(upto 5pm)</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <marquee behavior="scroll" direction="left" scrollamount="6" style={{cursor:'pointer'}}>
                                <a target="_blank" onClick={() => handleDownload(notice)} style={{ fontSize: '20px', color: '#007bff', textDecoration: 'none' }}>Resolutions of the Board meeting dated 14.08.2024 , 21.08.2024 and 28.08.24 are ready for delivery to the hearing parties.</a>
                            </marquee>
                        </div>

                        <div className="col-lg-3 d-flex align-items-center justify-content-center">
                            <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: '120px', height: '120px',cursor:'pointer' }}>
                                <i className="fa fa-star staricon" style={{ fontSize: '100px', color: '#FFD700' }}></i>

                                <div className="position-absolute top-50 start-50 translate-middle text-center" style={{ color: '#000', fontWeight: 'bold', fontSize: '10px', width: '80px', lineHeight: '1.2' }}>
                                    <span target="_blank" onClick={() => handleDownload(hostelAdmission)} className='khalidd'>Application Form for Hostel Admission <br /> 2024</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* -----------------update by khalid 19/10/24----------------     */}            

            <div className="container-fluid aboutback wow fadeInUp" >
                <div className="container py-3">
                    <div className="row p-2" style={{ border: '1px dashed #ccc' , borderRadius: '10px' }}>
                        <div className="col-lg-5 order-1 order-lg-2" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img ref={imageRef} className="position-absolute w-100 h-100 rounded wow zoomIn aboutus-image" src={admin} alt='#' style={{ objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="col-lg-7 order-2 khalid order-lg-1 mt-4">
                            <div className="section-title position-relative pb-3 py-2">
                                <h2 className="mb-0 heading-hover" style={{ color: '#347928' }}>Chairperson's Desk</h2>
                            </div>
                            <div className='khaliddd'>
                                <p>
                                    “Waqf property happens to be the property of Almighty Allah. It is our obligatory duty to
                                    protect and preserve the Waqf property for the welfare of the society. ”</p>
                            </div>
                            {/* <div className="row g-2 mb-3">
                                <div className="col-sm-6 khalid">
                                    <p className="mb-3">
                                        The Waqf Act 1995 defines the Waqf as the permanent dedication by any person of movable or immovable property for any purpose recognized by the Muslim law as pious, religious or charitable. The properties so dedicated are called Waqf.
                                    </p>
                                </div>
                                <div className="col-sm-6 khalid">
                                    <p className="mb-3">
                                        West Bengal Auqaf Board consists of elected and nominated members from various categories which are mentioned in the Waqf Act. The West Bengal Auqaf Board came into existence in the year 1936 following the Bengal “Waqf” Act, 1934.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid aboutback wow fadeInUp">
                <div className="container py-3">
                    <div className="row p-2" style={{ border: '1px dashed #ccc' , borderRadius: '10px' }}>
                        <div className="col-lg-5 order-1 order-lg-1" style={{ minHeight: '400px'}}>
                            <div className="position-relative h-100 w-100" style={{ backgroundColor: '#FAEC7F' }}>
                                <img ref={imageRef0} className="position-absolute w-100 h-100 rounded wow zoomIn aboutus-image" src={ceo} alt="#" style={{ objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="col-lg-7 order-2 khalid order-lg-1 mt-4">
                            <div className="section-title position-relative pb-3 py-3">
                                <h2 className="mb-0 heading-hover" style={{ color: '#347928' }}>CEO’S Desk</h2>
                            </div>

                            <div className='khaliddd khalid2'>
                                <p>
                                    “We salute the Waqifs who have dedicated their properties for the benefit and upkeeping of the community. Now, it is our duty to protect and preserve the waqf properties so that the desires of the Waqifs are fulfilled. ”</p>
                            </div>

                            {/* <div className="row g-2 mb-3">
                                <div className="col-sm-6 khalid2">
                                    <p className="mb-3">
                                        “We salute the Waqifs who have dedicated their properties for the benefit and upkeeping of the community. Now, it is our duty to protect and preserve the waqf properties so that the desires of the Waqifs are fulfilled.”
                                    </p>
                                </div>
                                <div className="col-sm-6 khalid2">
                                    <p className="mb-3">
                                        The Bengal Waqf Act, 1934, came into existence and thereafter, the Waqf Act, 1954 (Central Act) was in force except in West Bengal where the Bengal Waqf Act, 1934 was in force till the Promulgation of the Waqf Act, 1995 (Central Act).
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* -----------------update by khalid 05/10/24----------------     */}

            <div className="container-fluid fadeInUp">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 d-flex flex-column  align-items-center justify-content-center">
                            <div className="" style={{ width: '100%', height: '300px' }}>
                                <img src={adv1} alt="Left Ad" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                            <div className="" style={{ width: '100%', height: '250px' }}>
                                <img src={left} alt="Left Ad" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                            <div className="" style={{ width: '100%', height: '200px' }}>
                                <img src={left} alt="Left Ad" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                        </div>

                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <div className=" khalid3">
                                <h3 className='mb-4'>About Organization</h3>
                                <p className="mb-4">
                                    The word “Waqf” (Arabic: وقف‎‎) also known as habous has its origin in the Arabic word “Waqafa” meaning thereby to detain or to hold or tie up. It is said that once second Khalifa Omar acquired a piece of land in Khaibar and asked the prophet Mohammed as how best to make the most pious use of it. The prophet said “Tie up the property and devote the usufruct to the welfare of human beings, and it is not to be sold or made the subject of gift or inheritance, devote its produce to your children, your kindred and the poor and in the way of Allah”.
                                </p>
                                <p>During the administration of Mughals in the later stage, Aurangazeb codified the Muslim Laws including the Laws on Waqf through, “Fatawai-E- Alamgiri” which was valid till the period of the Privy Council in the British age.</p>
                                <h4 className='mt-4 mb-3'>1 .Waqf- the legal background:- </h4>
                                <p>In India, the Musalman Waqf Validating Act, 1913, the Musalman Waqf Act. 1923 were in vogue in the British period during the Privy Council.

                                    The Bengal Waqf Act, 1934, came into existence and thereafter , the Waqf Act, 1954 (Central Act) was in force except in West Bengal where the Bengal Waqf Act, 1934 was in force till the Promulgation of the Waqf Act, 1995 (Central Act).</p>
                                <p>The Waqf Act 1995 defines the Waqf as the permanent dedication by any person of movable or immovable property for any purpose recognized by the Muslim law as pious, religious or charitable. The properties so dedicated are called Waqf .The person who so dedicates his or her property is known as Waqif and the person who manages such property is known as Mutawalli (manager and administrator).</p>
                                <h5 className='mt-4' style={{ color: '#347928' }}><Link to="/welcome-to-board-of-auqaf-west-bengal">read more</Link></h5>

                            </div>
                        </div>

                        <div className="col-lg-3 d-flex flex-column  align-items-center justify-content-center">
                            <div className="" style={{ width: '100%', height: '300px' }}>
                                <img src={adv2} alt="Right Ad" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                            <div className="" style={{ width: '100%', height: '300px', marginTop: '100px' }}>
                                <img src={adv3} alt="Right Ad" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             {/* -----------------update by khalid 05/10/24----------------     */}

            <div>
                <div className="container-fluid aboutback fadeInUp">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="section-title text-center position-relative pb-3 mb-3 mt-5 mx-auto" style={{ maxWidth: '600px' }}>
                                <p className="text-center lead fs-4" ref={heading}  style={{ color:'#347928' , fontWeight:'500' }}>
                                    The Board of Auqaf, West Bengal has taken steps for better and more organized administration of waqf in the State in the last years.
                                </p>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center justify-content-center khalid4">
                                <p className="mb-4 fs-5">
                                    The word “Waqf” (Arabic: وقف‎‎) also known as habous has its origin in the Arabic word “Waqafa” meaning thereby to detain or to hold or tie up. It is said that once second Khalifa Omar acquired a piece of land in Khaibar and asked the prophet Mohammed as how best to make the most pious use of it. The prophet said “Tie up the property and devote the usufruct to the welfare of human beings, and it is not to be sold or made the subject of gift or inheritance, devote its produce to your children, your kindred and the poor and in the way of Allah”.
                                </p>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center justify-content-center border bg-white" style={{ minHeight: '350px' }}>
                                <div className="position-relative">
                                    <CarouselK />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Contactus />
            <Footer />
        </div>
    )
}
