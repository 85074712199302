import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css"
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { Contact } from "./Pages/Contact";
import { Tenders } from "./Pages/Tenders";
import { ImamMozzin } from "./Pages/ImamMozzin";
import { Hostel } from "./Pages/Hostel";
import { Notice_Board } from "./Pages/Notice_Board";
import { Stipend } from "./Pages/Stipend";
import { Recruitment } from "./Pages/Recruitment";
import { InfoAuqf } from "./Pages/InfoAuqf";
import { DownloadForms } from "./Pages/DownloadForms";
import { WelfareScheme } from "./Pages/WelfareScheme";
import { AuqafActRules } from "./Pages/AuqafActRules";
import { Organisation } from "./Pages/Organisation";
import { Gallery } from "./Pages/Gallery";


function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tender" element={<Tenders />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/imam_moazzin" element={<ImamMozzin />} />
          <Route path="/hostel" element={<Hostel />} />
          <Route path="/notice_board" element={<Notice_Board />} />
          <Route path="/stipend" element={<Stipend />} />
          <Route path="/recruitment" element={<Recruitment />} />
          <Route path="/welcome-to-board-of-auqaf-west-bengal" element={<InfoAuqf />} />
          <Route path="/download-forms-applications" element={<DownloadForms />} />
          <Route path="/welfare-scheme" element={<WelfareScheme />} />
          <Route path="/auqaf-act-rules-regulations" element={<AuqafActRules />} />
          <Route path="/board-of-members" element={<Organisation/>} />
          <Route path="/gallery" element={<Gallery/>} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
