import React from 'react'
const memo561 = "/Docs/tenders/Memo_No.561.pdf";
const memo932 = "/Docs/tenders/Memo_No.932.pdf";
const memo1342 = "/Docs/tenders/Memo_No.1342.pdf";
const memo1333 = "/Docs/tenders/Memo_No.1333.pdf";
const memo1332 = "/Docs/tenders/Memo_No.1332.pdf";
const memo1231 = "/Docs/tenders/Memo_No.1231.pdf";
const memo1192 = "/Docs/tenders/Memo_No.1192.pdf";
const memo1111 = "/Docs/tenders/Memo_No.1111.pdf";
const memo1106 = "/Docs/tenders/Memo_No.1106.pdf";
const memo1060 = "/Docs/tenders/Memo_No.1060.docx";
const memo1003 = "/Docs/tenders/Memo_No.1003.pdf";


export const Tender = () => {

    const tendersData = [
        {
            date: "02.09.24",
            subject: "Tender/Quotation Notice for collecting rent from temporary shop for the purpose of ‘URS’ at Mallickpur, South 24 Parganas in respect of Habib Abdullah Waqf Estate",
            memo: "Memo No. 561 Dtd. 02.09.24",
            download: memo561,
            keywords: "Last Date for submission of tender application 09.09.24 Last Date for receiving of tender paper 09.09.24 up to 2:00 p.m. Opening of Tender 09.09.24 at 3:00 P.M.",
        },
        {
            date: "13.09.23",
            subject: "Tender/Quotation Notice for collecting rent from temporary shop for the purpose of ‘URS’ at Mallickpur, South 24 Parganas in respect of Habib Abdullah Waqf Estate",
            memo: "Memo No. 932 Dtd. 13.09.23",
            download: memo932,
            keywords: "Last Date for submission of tender application 19.09.23 Last Date for receiving of tender paper 19.09.23 up to 2:00 p.m. Opening of Tender 19.09.23 at 3:00 P.M.",
        },
        {
            date: "30.11.2022",
            subject: "Tender/Quotation Notice for repairing and Refitting window glass connection of pipe line etc. at Boo Ali Muslim Boys Hostel at 1, Kaiser St. Sealdah, Kolkata – 700 009.",
            memo: "Memo No. 1342 Dtd. 29.11.2022",
            download: memo1342,
            keywords: "Last Date for submission of tender application 05.12.2022 Last Date for receiving of tender paper 14.12.2022 up to 2:00 p.m. Opening of Tender 14.12.2022 at 3:00 P.M.",
        },
        {
            date: "30.11.2022",
            subject: "Tender/Quotation Notice for printing of Executive Diary’2023.",
            memo: "Memo No. 1333 Dtd. 28.11.2022",
            download: memo1333,
            keywords: "Last Date 01.12.2022 from 11:30 a.m. to 1:30 p.m.",
        },
        {
            date: "30.11.2022",
            subject: "Tender/Quotation Notice for printing of Greetings Card & Calendar’2023.",
            memo: "Memo No. 1332 Dtd. 28.11.2022",
            download: memo1332,
            keywords: "Last Date 01.12.2022 from 11:30 a.m. to 1:30 p.m.",
        },
        {
            date: "27.09.2022",
            subject: "Tender Notice for supplying and installing IP CCTV at Ghutiari Sarif Mazar Waqf Estate (E.C. No. 4115), Bansara, Dist. 24 Parganas (South), W.B.",
            memo: "Memo No. 1231 Dtd. 26.09.2022",
            download: memo1231,
            keywords: "Last Date 12.10.2022 Opening of Tender 19.10.2022 at 3:00 P.M.",
        },
        {
            date: "15.09.2022",
            subject: "Tender Notice for collecting rent from temporary shop at the time “URS” in respect of Habib Abdullah Waqf Estate, Mallickpur, 24Pgs (S).",
            memo: "Memo No. 1192 Dtd. 13.09.2022",
            download: memo1192,
            keywords: "Last Date 21.09.2022 up to 2:00 p.m. Opening of quotation 21.09.2022 at 3:00 p.m.",
        },
        {
            date: "18.08.2022",
            subject: "Tender Quotation Notice for (i) Computer, Printer, & UPS, (ii) WIFI, (iii) Website & (iv) CCTV & Bio-Matric",
            memo: "Memo No. 1111",
            download: memo1111,
            keywords: "Last Date 30.08.2022 From 11:30 a.m. to 1:30 p.m Opening of quotation 30.08.2022 at 2:30 p.m.",
        },
        {
            date: "16.08.2022",
            subject: "Tender Notice for repairing, renovation of existing old mosque at 100C, Beleghata Main Road, Kol- 10",
            memo: "Memo No. 1106",
            download: memo1106,
            keywords: "Last Date 01.09.2022 Opening of quotation 09.09.2022 at 3:00 p.m.",
        },
        {
            date: "02.08.2022",
            subject: "Tender Notice for painting at basement flr. of Kolkata Muslim Girls Hostel at 43, Dilkhusa St., Kol – 17",
            memo: "Memo No. 1060",
            download: memo1060,
            keywords: "Last Date 16.08.2022 Opening of quotation 24.08.2022 at 3:00 p.m.",
        },
        {
            date: "20.07.2022",
            subject: "Quotation Notice for several Items",
            memo: "Memo No. 1003",
            download: memo1003,
            keywords: "Opening of quotation 28.07.2022 at 2:30 p.m.",
        },

    ];
    const handleDownload = (path) => {
        if (path) {
          window.open(path, "_blank", "noopener,noreferrer");
        } else {
          console.error("Download file not found.");
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Tenders</h2>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr>
                            <th>Date</th>
                            <th>Subject</th>
                            <th>Memo No./Ref. No.</th>
                            <th>Click to Download</th>
                            <th>Keywords</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tendersData.map((tender, index) => (
                            <tr key={index}>
                                <td>{tender.date}</td>
                                <td>{tender.subject}</td>
                                <td>{tender.memo}</td>
                                <td>
                                    <a target="_blank" onClick={() => handleDownload(tender.download)} className="btn btn-primary btn-sm">Download</a>
                                </td>
                                <td>{tender.keywords}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
