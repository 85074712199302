import React from 'react'
import { TopNavbar } from '../Componants/TopNavbar'
import { Navbar } from '../Componants/Navbar'
import { NoticeBoardNotification } from '../Componants/Notice/NoticeBoardNotification';
import Footer from "../Componants/Footer";
import { NoticeWithMemoNumber } from '../Componants/Notice/NoticeWithMemoNumber';
import { NoticeMoreInformation } from '../Componants/Notice/NoticeMoreInformation';
import { NoticePhoto } from '../Componants/Notice/NoticePhoto';

export const Notice_Board = () => {
  return (
    <div>
      <TopNavbar />
      <div className="container-fluid position-relative p-0">
        <Navbar />
        <div className="container-fluid bg-primary py-5 bg-header" >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">GENERAL NOTICE for Enrolment as Waqf</h1>
              <a className="h6 text-white">Home</a>
              <i className="fa fa-angle-right text-white px-2"></i>
              <a className="h6 text-white">Notice Board</a>
            </div>
          </div>
        </div>
        <NoticeBoardNotification />
        <NoticeWithMemoNumber />
        <NoticeMoreInformation />
        <NoticePhoto />
      </div>
      <Footer />
    </div>
  )
}
