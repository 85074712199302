import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import "../App.css"
import logo from "../images/AuqafLogo.png"

export const Navbar = () => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);

    useEffect(() => {
        // Sticky Navbar
        const handleScroll = () => {
            if ($(window).scrollTop() > 45) {
                $('.navbar').addClass('sticky-top shadow-sm');
            } else {
                $('.navbar').removeClass('sticky-top shadow-sm');
            }
        };

        $(window).on('scroll', handleScroll);

        return () => {
            $(window).off('scroll', handleScroll);
        };
    }, []);

    const handleToggle = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };

    const handleSolutionsToggle = () => {
        setIsSolutionsOpen(!isSolutionsOpen);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
                <img className='logo_img' src={logo} alt="Logo"></img>
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded={!isNavbarCollapsed}
                aria-label="Toggle navigation"
                onClick={handleToggle}
            >
                <span className="fa fa-bars"></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}>
                <div className="navbar-nav ms-auto navvvv py-0">
                    <Link to="/" className="nav-item nav-link active">Home</Link>
                    <Link to="/about" className="nav-item nav-link">About</Link>
                    <Link to='/notice_board' className="nav-item nav-link">Notice Board </Link>
                    <div className={`nav-item dropdown ${isSolutionsOpen ? 'show' : ''}`}>
                        <a href="/" className="nav-link kkk dropdown-toggle" data-bs-toggle="dropdown" onClick={handleSolutionsToggle}>Useful link</a>
                        <div className={`dropdown-menu m-0 ${isSolutionsOpen ? 'show' : ''}`}>
                            <Link to='/imam_moazzin' className="dropdown-item">Imam & Moazzin Info.</Link>
                            <Link to='/hostel' className="dropdown-item">Hostel</Link>
                            <Link to="/download-forms-applications" className="dropdown-item">Download forms</Link>
                            <Link to="/gallery" className="dropdown-item">Gallery</Link>
                            {/* <Link to='/notice_board' className="dropdown-item">Notice Board / Notification</Link> */}
                            <Link to='/auqaf-act-rules-regulations' className="dropdown-item">Waqf Act, Rules and Regulations</Link>
                            <Link to='/stipend' className="dropdown-item">Stipend</Link>
                            <Link to='/recruitment' className="dropdown-item">Recruitment</Link>
                            <Link to='/welfare-scheme' className="dropdown-item">Welfare Scheme</Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <Link to="/tender"><a className="nav-item nav-link">Tenders</a></Link>
                    </div>
                    <Link to="/board-of-members" className="nav-item nav-link">Organisation</Link>
                    {/* <Link to="/gallery" className="nav-item nav-link">Gallery</Link> */}
                    <Link to="/contact" className="nav-item nav-link">Contact</Link>
                </div>
            </div>
        </nav>
    )
}
