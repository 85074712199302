import React from 'react'
const notice = "/Docs/hostel/NOTICE.pdf";
const notice21 = "/Docs/hostel/Notice22.11.21.pdf";
const hostelAdmission = "/Docs/hostel/HOSTEL-ADMISSION-FORM.pdf";


export const HostelComponent = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Hostel Admission Information</h2>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    Hostel Admission 2024
                </div>
                <div class="card-body">
                    <p>Hostel Admission in Kolkata under Auqaf Board, W.B.</p>
                    <div class="d-flex gap-2 justify-content-between">
                        <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-info">For more details {'>>'} Click Here</a>
                        <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-success">Download Form {'>>'} Click Here</a>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header bg-secondary text-white">
                    Hostel Admission 2023
                </div>
                <div class="card-body">
                    <p>Hostel Admission in Kolkata under Auqaf Board, W.B.</p>
                    <div class="d-flex gap-2 justify-content-between">
                        <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-info">For more details {'>>'} Click Here</a>
                        <a target="_blank" onClick={() => handleDownload(notice)} class="btn btn-success">Download Form {'>>'} Click Here</a>
                    </div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header bg-dark text-white">
                    Hostel Admission 2022
                </div>
                <div class="card-body">
                    <p>Hostel Admission in Kolkata under Auqaf Board, W.B.</p>
                    <div class="d-flex gap-2 justify-content-between">
                        <a target="_blank" onClick={() => handleDownload(notice21)} class="btn btn-info">For more details {'>>'} Click Here</a>
                        <a target="_blank" onClick={() => handleDownload(hostelAdmission)} class="btn btn-success">Download Form {'>>'} Click Here</a>
                    </div>
                </div>
            </div>
        </div>

    )
}
