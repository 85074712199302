import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import "../App.css";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const Abouts = () => {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".about p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".about span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".about", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".about1 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".about1 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".about1", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".about2 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".about2 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".about2", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const paragraphs = document.querySelectorAll(".about3 p");
        paragraphs.forEach(paragraph => {
            const text = paragraph.textContent;
            const splitWords = text.split(" "); 
            const wrappedText = splitWords.map(word => `<span>${word} </span>`).join(""); 
            paragraph.innerHTML = wrappedText; 
        });

        gsap.to(".about3 span", {
            color: "#17a2b8", 
            stagger: 0.1, 
            scrollTrigger: {
                trigger: ".about3", 
                start: "top 90%",
                end: "bottom 20%", 
                scrub: 1, 
                markers: false 
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div>
            <section class="py-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 text-left about">
                            <h1 class="display-4 mb-4">About Us</h1>
                            <p class="lead ">
                                The <strong>West Bengal Auqaf Board</strong> is a statutory body established by the Government of West Bengal under the <strong>Waqf Act, 1995</strong> (Central Act 43 of 1995). The Board is constituted in accordance with the provisions of <strong>Section 14 of the Waqf Act, 1995</strong>, and is comprised of both elected and nominated members. The <strong>Chairperson</strong> of the Board is elected from amongst its members, and the current Chairperson is a retired Justice of the Hon'ble High Court, Calcutta.
                            </p>
                            <p class="lead">
                                The West Bengal Auqaf Board oversees numerous Waqf properties across the state, including <strong>Mosques, Kabarsthan, Dargahs,</strong> and other registered Waqf properties. The Board functions with <strong>perpetual succession</strong> and a <strong>common seal</strong>, with the authority to acquire, hold, and manage properties in accordance with the law.
                            </p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-6 about1">
                            <h3 class="mb-3">Our Functions</h3>
                            <p>
                                <p>1. <strong>General Superintendence</strong> of all Waqf estates and their properties in the state.</p>
                                <p>2. <strong>Ensuring Maintenance and Administration:</strong> The Board exercises its powers under the Waqf Act to ensure that the Waqf properties under its supervision are properly maintained, controlled, and administered. The income generated from these properties is applied to the purposes for which the Waqf was created or intended.</p>
                            </p>
                        </div>
                        <div class="col-md-6 about2">
                            <h3 class="mb-3">Governance and Administration</h3>
                            <p>
                                The <strong>State Government</strong> , in consultation with the Board, appoints a <strong>Chief Executive Officer (CEO)</strong> to manage the Board's operations. Additionally, the Board is empowered to appoint officers and employees, as deemed necessary for its functioning, in consultation with the State Government under <strong>Section 24 of the Waqf Act, 1995.</strong> All employees of the Board are considered <strong>public servants</strong> under <strong>Section 21 of the Indian Penal Code (45 of 1860).</strong>
                            </p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-12 about3">
                            <h3 class="mb-3">Central Waqf Council (CWC)</h3>
                            <p>
                                The <strong>Central Waqf Council (CWC)</strong>, established by the Government of India in 1964 under the <strong>Waqf Act, 1954</strong> (now a subsection of the Waqf Act, 1995), serves as an advisory body on matters related to the functioning of the State Auqaf Boards and the administration of Waqf properties across the country. The CWC works closely with State Auqaf Boards like the <strong>West Bengal Auqaf Board</strong>r to ensure proper administration and management in compliance with sections 13 and 14 of the Waqf Act, 1995.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
