import React from 'react'
const FormStipend = "/Docs/download/Form-Stipend.pdf";
const noticeStipend = "/Docs/download/Stipend-notice.pdf";

export const StipendComponent = () => {

    const handleDownload = (path) => {
        if (path) {
            window.open(path, "_blank", "noopener,noreferrer");
        } else {
            console.error("Download file not found.");
        }
    };

    return (
        <div class="container mt-5">
            <h2 class="text-center mb-4">Stipend Information</h2>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    Application for Scholarship of Muslim Students for the Financial Year 2021-22
                </div>
                <div class="card-body">
                    <p class="mb-4">Details regarding the scholarship application for Muslim students for the financial year 2021-22.</p>
                    <div class="d-flex justify-content-start gap-3">
                        <a target="_blank" onClick={() => handleDownload(noticeStipend)} class="btn btn-warning">New Notice</a>
                        <a target="_blank" onClick={() => handleDownload(FormStipend)} class="btn btn-success">Download Form</a>
                    </div>
                </div>
            </div>
        </div>

    )
}
