import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "../App.css"

gsap.registerPlugin(ScrollTrigger);

export const Contactus = () => {
    const heading = useRef(null);


    useEffect(() => {
        gsap.fromTo(
            heading.current,
            { scale: .9 },
            {
                scale: 1,
                duration: 2,  
                ease: 'power2.out',  
                scrollTrigger: {
                    trigger: heading.current,
                    start: 'top 80%',  
                    end: 'top 20%',  
                    scrub: 1,  
                },
            }
        );

    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-fluid py-3 wow fadeInUp" >
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                    <h2 className="mb-0 " ref={heading} style={{ color:'#347928' }}>If You Have Any Query, Feel Free To Contact Us</h2>
                </div>
                <div className="row g-5 mb-5  ">
                    <div className="col-lg-4" >
                        <div className="d-flex align-items-center wow fadeIn">
                            <div className="colorb d-flex align-items-center justify-content-center rounded" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-phone-alt text-black fs-5"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Call to ask any question</h6>
                                <h5 className="color mb-0">+91-1234567890
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" >
                        <div className="d-flex align-items-center wow fadeIn">
                            <div className="colorb d-flex align-items-center justify-content-center rounded" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-envelope-open text-black fs-5"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Email to get free quote</h6>
                                <h5 className="color mb-0">ceoboardofwakfswb@gmail.com</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center wow fadeIn" >
                            <div className="colorb d-flex align-items-center justify-content-center rounded" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-map-marker-alt text-black fs-5"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Visit our office</h6>
                                <h5 className="color mb-0"> 6/2, Madan Street ,<br /> Kolkata – 700 072
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt-3">
                    <div className="col-lg-6 wow slideInUp" >
                        <div className="section-title  position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '400px' }}>
                            <h2 className="mb-0 "><span className='color1'>Let's </span>Connect</h2>
                        </div>
                        <div className='contactDiv'>
                            <p className='textHead'>BOARD OF AUQAF, WEST BENGAL</p>
                            <p>Under</p>
                            <p className='textHead1'>MINORITY AFFAIRS & MADRASAH EDUCATION DEPARTMENT</p>
                            <p>(GOVERNMENT OF WEST BENGAL)</p>
                            <p>6/2, Madan Street, Kolkata – 700 072</p>
                            <p>Tele/Fax : <span className='textHead2'>(033) 2212-6431 / 6523 / 7722</span></p>
                            <p> Mail : <span className='textHead2'>ceoboardofwakfswb@gmail.com</span></p>
                        </div>


                    </div>
                    <div className="col-lg-6 ">
                        <form>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <input type="text" className="form-control border-0 bg-light px-4" placeholder="Your Name" style={{ height: '50px' }} />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" className="form-control border-0 bg-light px-4" placeholder="Your Email" style={{ height: '50px' }} />
                                </div>
                                <div className="col-12">
                                    <input type="number" className="form-control border-0 bg-light px-4" placeholder="Your Number" style={{ height: '50px' }} />
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control border-0 bg-light px-4" placeholder="Subject" style={{ height: '50px' }} />
                                </div>
                                <div className="col-12">
                                    <textarea className="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-50 py-3" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
